<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Delivery Order : {{DeliveryOrderNumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor DO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="DeliveryOrderNumber" v-model="DeliveryOrderNumber" class="font-weight-bold" />
                            <label id="errorDeliveryOrderNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                :disabled="DelveryOrderDateDisabled"
                                v-model="DeliveryOrderDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDeliveryOrderDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="DeliveryOrderPoNo" v-model="DeliveryOrderPoNo" class="font-weight-bold" />
                            <label id="errorDeliveryOrderPoNo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Customer</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="DeliveryOrderCustomer" v-model="DeliveryOrderCustomer" class="font-weight-bold" />
                            <label id="errorDeliveryOrderCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tipe Pengiriman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="ShippingType" class="font-weight-bold" :options="ShippingTypeData" :value.sync="ShippingType" />
                            <label id="errorShippingType" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Note" v-model="Note" class="font-weight-bold" />
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div v-if="this.ShippingType == 8" style="width:100%">
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Ekspedisi</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <v-select id="Expedition" class="pb-3" :options="ExpeditionData" v-model="Expedition"/>
                                <label id="errorExpedition" class="form-error" style="display: none; color: red;"></label>
                            </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Catatan Delivery</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <textarea class="form-control" rows="2" v-model="AdditionalNote"></textarea>
                                <label id="errorAdditionalNote" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>
                        </div>
                        <div v-if="this.ShippingType == 9" style="width:100%">
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Kendaraan</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <v-select id="Vehicle" class="pb-3" :options="VehicleData" v-model="Vehicle"/>
                                <label id="errorVehicle" class="form-error" style="display: none; color: red;"></label>
                            </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Driver</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <v-select id="Driver" class="pb-3" :options="DriverData" v-model="Driver"/>
                                <label id="errorDriver" class="form-error" style="display: none; color: red;"></label>
                            </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Catatan Delivery</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <textarea class="form-control" rows="2" v-model="AdditionalNote"></textarea>
                                <label id="errorAdditionalNote" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>
                        </div>
                        <div v-if="this.ShippingType == 10" style="width:100%">
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Catatan Delivery</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <textarea class="form-control" rows="2" v-model="AdditionalNote"></textarea>
                                <label id="errorAdditionalNote" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>

                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :pageable="true"
                                        :resizable="true"
                                        >

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'barcode'"
                                                    :title="'Bar Code'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'type_detail_id'"
                                                    :title="'Tipe Detail'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'ordered_quantity'"
                                                    :title="'Ordered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'delivered_quantity'"
                                                    :title="'Delivered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'remaining_quantity'"
                                                    :title="'Remaining Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Delivery Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                            </kendo-grid>
                        </div>
                    </CRow>
                    
                    <br>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                            <label id="errorPOGeneral" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/delivery-order')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import moment from 'moment';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import deliveryOrderServices from '../Script/DeliveryOrderServices';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FileViewer from '../../../../shared/FileViewer.vue';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'DeliveryOrderFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            document.getElementById('DeliveryOrderNumber').readOnly = false;
            document.getElementById('DeliveryOrderCustomer').readOnly = true;
            document.getElementById('DeliveryOrderPoNo').readOnly = true;

            var poData = await deliveryOrderServices.getDeliveryOrderablDataDetail(this.Id);
            //Get Date
            this.DelveryOrderDateDisabled = false;
            
            var tomorrowDate = moment(new Date() + 1).add(1, 'days').format("YYYY-MM-DD");
            this.DeliveryOrderId = '';
            this.DeliveryOrderNumber = 'Auto';
            this.DeliveryOrderCustomer = poData.customer_name;
            this.DeliveryOrderDate = tomorrowDate;
            this.DeliveryOrderPoNo = poData.purchase_order_number;
            this.PurchaseOrderId = poData.purchase_order_id;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.Note = '';

            //Shipping
            this.ShippingTypeData = await this.$globalfunc.globalTypeLookupDropdown("delivery_order_type");
            this.ShippingType = 8;
            this.ExpeditionData = await deliveryOrderServices.getContactQuery("Expedition");
            this.Expedition = null;
            this.VehicleData = await deliveryOrderServices.getVehicleQuery();;
            this.Vehicle = null;
            this.DriverData = await deliveryOrderServices.getContactQuery("Driver");
            this.Driver = null;
            this.AdditionalNote = null;

            //File
            this.File = '';
            this.FileStatus = 'New';

            // this.ItemGridData = this.$globalfunc.objectToArrayConverter(poData.delivery_orderable_detail, "TransactionDO-ItemDODetail")
            this.ItemGridData = deliveryOrderServices.itemGridDataEditable(poData.delivery_orderable_detail);

            this.SaveType = 'Add';
        } 
        else {
            document.getElementById('DeliveryOrderNumber').readOnly = true;
            document.getElementById('DeliveryOrderCustomer').readOnly = true;
            document.getElementById('DeliveryOrderPoNo').readOnly = true;

            var doData = await deliveryOrderServices.getDeliveryOrderDetail(this.Id);
            
            if (doData.status != "New") {
                this.DelveryOrderDateDisabled = true
            }
            
            this.DeliveryOrderId = doData.delivery_order_id;
            this.DeliveryOrderNumber = doData.delivery_order_number;
            this.DeliveryOrderCustomer = doData.customer_name;
            this.DeliveryOrderDate = doData.delivery_order_date;
            this.DeliveryOrderPoNo = doData.purchase_order_number;
            this.PurchaseOrderId = doData.purchase_order_id;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_delivery_order');
            this.Status = doData.status;
            this.Note = doData.notes;

            //Shipping
            this.ShippingTypeData = await this.$globalfunc.globalTypeLookupDropdown("delivery_order_type");
            this.ShippingType = doData.delivery_order_type_id;
            this.ExpeditionData = await deliveryOrderServices.getContactQuery("Expedition");
            this.Expedition = this.ExpeditionData.find(c => c.value == doData.expedition_id);
            this.VehicleData = await deliveryOrderServices.getVehicleQuery();
            this.Vehicle = this.VehicleData.find(c => c.value == doData.vehicle_id);
            this.DriverData = await deliveryOrderServices.getContactQuery("Driver");
            this.Driver = this.DriverData.find(c => c.value == doData.driver_id);
            this.AdditionalNote = doData.additional_notes;

            //File
            this.File = doData.attachment;
            this.FileStatus = 'Uploaded';
            
            this.ItemGridData = deliveryOrderServices.itemGridDataEditable(doData.delivery_order_detail);
            
            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            //Detail
            Id: this.$route.params.id,
            PurchaseOrderId: '',
            DeliveryOrderId: '',
            DeliveryOrderNumber: '',
            DeliveryOrderCustomer: '',
            DeliveryOrderDate: '',
            DeliveryOrderPoNo: '',
            DelveryOrderDateDisabled: '',
            StatusData: [],
            Status: '',
            Note: '',
            File: '',
            FileStatus: '',

            ShippingTypeData: [],
            ShippingType: '',
            ExpeditionData: [],
            Expedition: '',
            VehicleData: [],
            Vehicle: '',
            DriverData: [],
            Driver: '',
            AdditionalNote : '',

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                barcode: { type: "string", editable: false },
                type_detail_id: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                ordered_quantity: { type: "number", editable: false },
                delivered_quantity: { type: "number", editable: false },
                remaining_quantity: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:0} },
                notes: { type: "string", editable: true },
                old_remaining_qty: { type: "number", editable: false },
            },
        }   
    },
    methods: {
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            //item GridData
            var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;

            if (this.DeliveryOrderDate == '' || this.DeliveryOrderDate == null) {
                this.errorShow('errorDeliveryOrderDate');
            }

            if (gridItem.length == 0) {
                this.$swal("Error", "Item tidak boleh kosong", "error");
                this.Error++;
            }

            //Validation Qty_DO > Qty_PO
            for (let i = 0; i < gridItem.length; i++) {
                if (gridItem[i].quantity > gridItem[i].old_remaining_quantity) {
                    this.Error++;
                    this.$swal("Error", "Delivery quantity tidak boleh lebih besar dari remaining quantity ", "error");
                }
            }

            if(this.ShippingType == 8){
                if (this.Expedition == null || this.Expedition == '') {
                    this.errorShow('errorExpedition');
                }
            }
            else if(this.ShippingType == 9){
                if (this.Driver == null || this.Driver == '') {
                    this.errorShow('errorDriver');
                }
                if (this.Vehicle == null || this.Vehicle == '') {
                    this.errorShow('errorVehicle');
                }
            }
        },
        async saveClick(){
            this.inputValidation();

            //file
            var file = null
            if(this.File == '' || this.File == null){
                file = null
            } else {
                file = document.getElementById('File').files[0];
            };

            //Proses
            if(this.Error == 0){
                var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;
                var detailDO_Array = globalfunc.objectToArrayConverter(gridItem, "TransactionDO-DetailDOArrayInput");

                const doData = {
                    delivery_order_type_id: parseInt(this.ShippingType),
                    delivery_order_number: this.DeliveryOrderNumber,
                    status: this.Status,
                    delivery_order_date: this.DeliveryOrderDate,
                    purchase_order_id: parseInt(this.PurchaseOrderId),
                    notes: this.Note,
                    attachment: file,
                    driver_id: null,
                    vehicle_id: null,
                    expedition_id: null,
                    additional_notes: this.AdditionalNote,
                    transaction_delivery_order_detail: detailDO_Array
                }

                if(this.ShippingType == 8){
                    doData.expedition_id = this.Expedition.value;
                }
                else if(this.ShippingType == 9){
                    doData.driver_id = this.Driver.value;
                    doData.vehicle_id = this.Vehicle.value;
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : doData
                    }

                    deliveryOrderServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/delivery-order' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.DeliveryOrderId),
                        data : doData
                    }
                    
                    deliveryOrderServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/delivery-order'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    } 
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>