<template>
    <div>
        <div class="modal fade" id="DeliveryOrderCreateModal" tabindex="-1" aria-labelledby="DeliveryOrderCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="DeliveryOrderCreateModalLabel" class="font-weight-bold">Add Delivery Order</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :columns="columns"
                            :resizable="true"
                        >
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import deliveryOrderService from '../Script/DeliveryOrderServices';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryOrderCreateForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['createDetailClick'],
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem.purchase_order_id);
        })
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> </div>'
                },
                { 
                    title: "Informasi PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    columns: [
                        { field:"sp_number", title: "No. SP", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"purchase_order_number", title: "PO Customer", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"purchase_order_date", title: "Tanggal", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                            template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" 
                        },
                        { field:"delivery_order_amount", title: "Jumlah DO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, filterable: false },
                        { field:"purchase_order_to_delivery_order_item_amount", title: "Item PO/DO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, filterable: false },
                        { field:"project_type", title: "Tipe", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                            template: '#= project_type == 1 ? "Project" : "Retail" #'
                        },
                        { field:"approver", title: "App. Acc", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    ]
                },
                { 
                    title: "Customer", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    columns: [
                        { field:"customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"customer_meta.profile.phone1", title: "No. Telp", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"customer_meta.profile.handphone", title: "No. Hp", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    ] 
                },
                { 
                    title: "Project", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    columns: [
                        { field:"project_name", title: "Project", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"sales_name", title: "Sales", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    ] 
                },
                { 
                    title: "Payment", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    columns: [
                        { field:"project_detail.cash", title: "Tipe", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"term_of_payment", title: "Jatuh Tempo (Hari)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    ] 
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_form_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            return { 
                                query: deliveryOrderService.GetDeliveryOrderableData()
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionDeliveryOrderableItemListV2 == null){
                            return [];
                        }else{
                            return response.data.GetTransactionDeliveryOrderableItemListV2;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionDeliveryOrderableItemListV2 == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionDeliveryOrderableItemListV2.length;
                        }
                    },
                    model: {
                        fields: {
                            purchase_order_date: {type: "date"},
                            term_of_payment: {type:"number"}
                        }
                    }
                },
                sort: { field: "purchase_order_date", dir: "desc" }
            });
            
            window.$('#DeliveryOrderCreateModal').modal('show');
        },
        createDetailClick(data){
            window.$('#DeliveryOrderCreateModal').modal('hide');
            this.$router.push({ name: 'Delivery Order Form', params: {  formtype:'Add', id:data, view:'new' } })
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>