<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Delivery Order</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <deliveryOrder-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <deliveryOrder-create-form ref="DeliveryOrderCreateForm" :reload="reload"/>
                <deliveryOrder-status-form ref="DeliveryOrderStatusForm" :reload="reload"/>
                <deliveryOrder-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import DeliveryOrderServices from '../DeliveryOrder/Script/DeliveryOrderServices.js';
import DeliveryOrderGrid from '../DeliveryOrder/Grid/DeliveryOrderGrid.vue';
import DeliveryOrderCreateForm from '../DeliveryOrder/Component/DeliveryOrderCreateForm.vue';
import DeliveryOrderStatusForm from '../DeliveryOrder/Component/DeliveryOrderStatusForm.vue';
import DeliveryOrderPDF from '../../../assets/template/DeliveryOrderPdfTemplate.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryOrder',
    components: {
        'deliveryOrder-grid': DeliveryOrderGrid,
        'deliveryOrder-create-form': DeliveryOrderCreateForm,
        'deliveryOrder-status-form': DeliveryOrderStatusForm,
        'deliveryOrder-pdf': DeliveryOrderPDF
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Delivery Order');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.DeliveryOrderCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Delivery Order Form', params: {  formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Delivery Order Form', params: {  formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await DeliveryOrderServices.getDeliveryOrderDetail(id);
            var detailDO_Array = globalfunc.objectToArrayConverter(data.delivery_order_detail, "TransactionDO-DetailDOArrayInput");

            const doData = {
                delivery_order_type_id: data.delivery_order_type_id,
                delivery_order_number: data.delivery_order_number,
                status: "Delete",
                delivery_order_date: data.delivery_order_date,
                purchase_order_id: parseInt(data.purchase_order_id),
                notes: data.notes,
                attachment: null,                    
                transaction_delivery_order_detail: detailDO_Array
            }

            const variables = {
                id: parseInt(id),
                data : doData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    DeliveryOrderServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.DeliveryOrderStatusForm.statusClick(data);
        },
        pdfClick(id) {
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.pdf.generatePDF(id);
            //     }else if (result.isDenied) {
            const routeData = this.$router.resolve({name: 'Delivery Order Html Template', params: { id:id }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_delivery_order', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnCancel").on('click', function() {
                    vue.$refs.grid.changeStatus('Cancel');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnPrepare").on('click', function() {
                    vue.$refs.grid.changeStatus('Prepare');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>