<template>
    <div>
        <kendo-grid 
                ref="grid"
                :data-source="dataSource"
                :pageable="pageableConfig"
                :filterable="filterableConfig"
                :sortable="true"
                :columns="columns"
                v-on:detailinit="detailInit"
                :resizable="true"
        >
        </kendo-grid>

        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import DeliveryOrderService from '../Script/DeliveryOrderServices.js';
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryOrderGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function(){
        return {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_delivery_order\".\"status\"" },
                                { field: "created_at", new_field: "\"transaction_delivery_order\".\"created_at\"" },
                                { field: "customer_name", new_field: "\"PurchaseOrder__Customer\".contact_name" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                                { field: "notes", new_field: "\"transaction_delivery_order\".\"notes\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: DeliveryOrderService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionDeliveryOrder.transaction_delivery_order == null)
                        {
                            return [];
                        }
                        else
                        {
                            return response.data.GetTransactionDeliveryOrder.transaction_delivery_order;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionDeliveryOrder.transaction_delivery_order == null)
                        {
                            return [];
                        }
                        else
                        {
                            return response.data.GetTransactionDeliveryOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            delivery_order_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "delivery_order_number", title: "No. DO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "delivery_order_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(delivery_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "sp_number", title: "No. SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "purchase_order_number", title: "No. PO Cust", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name",title: "Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "attachment", title: "Attachment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "additional_notes", title: "Catatan Delivery", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var vueComponent = this;
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var pdfClick = this.$props.pdfClick;
        
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.delivery_order_id, false);
        })
        
        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.delivery_order_id, true);
        })

        
        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.delivery_order_id);
        })

        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const doData = {
                delivery_order_id : dataItem.delivery_order_id,
                delivery_order_number : dataItem.delivery_order_number,
                status : dataItem.status
            }
            
            statusClick(doData);
        })

        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.delivery_order_id)
        })

        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })

    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionWithStatusChangesButton("Delivery Order", customButton)
        },
        detailInit: async function (e) {
            var data = await DeliveryOrderService.getDeliveryOrderDetail(e.data.delivery_order_id);
            var html = DeliveryOrderService.DeliveryOrderDetailTemplate(data);

            $('<div/>').appendTo(e.detailCell).html(html);
            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Daftar Barang</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.delivery_order_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                },
                pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'quantity', title: "Jumlah", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                    { field: 'notes', title: "Catatan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                ],
            })
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_delivery_order\".\"status\"" },
                                { field: "created_at", new_field: "\"transaction_delivery_order\".\"created_at\"" },
                                { field: "customer_name", new_field: "\"PurchaseOrder__Customer\".contact_name" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                                { field: "notes", new_field: "\"transaction_delivery_order\".\"notes\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: DeliveryOrderService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionDeliveryOrder.transaction_delivery_order == null)
                            return [];
                        else
                            return response.data.GetTransactionDeliveryOrder.transaction_delivery_order;
                    },
                    total: function(response) {
                        if(response.data.GetTransactionDeliveryOrder.transaction_delivery_order == null)
                            return 0;
                        else
                            return response.data.GetTransactionDeliveryOrder.total;
                    },
                    model: {
                        fields: {
                            delivery_order_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    }
}
</script>

<style scoped>
</style>